import * as React from "react"

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 240 90" {...props}>
      <defs>
        <style>
          {".cls-1{fill:#2d434f;}.cls-2{fill:#a7b4be;}.cls-3{fill:#2b414e;}.cls-4{fill:#475e6b;}.cls-5{fill:#f70141;}"}
        </style>
      </defs>
      <g id="Guidelines">
        <g id="_2ASWYW.tif" data-name="2ASWYW.tif">
          <path
            className="cls-1"
            d="M210,58.66a15.24,15.24,0,0,1-2.72,1.41,10.31,10.31,0,0,1-7.28.08,6.09,6.09,0,0,1-3.79-4.58,12.51,12.51,0,0,1-.33-3c0-3.38,0-6.76,0-10.14,0-.38-.1-.47-.47-.46-1.27,0-2.54,0-3.81,0-.31,0-.38-.09-.38-.39,0-.9,0-1.79,0-2.69,0-.34.1-.4.41-.39,1.29,0,2.57,0,3.86,0,.33,0,.39-.1.39-.4,0-2,0-4,0-6,0-.37.11-.45.46-.44,1,0,2,0,3,0,.32,0,.42.07.41.4,0,2,0,4,0,6,0,.36.09.44.44.44h8.32c.33,0,.43.08.42.42,0,.87,0,1.74,0,2.61,0,.37-.12.44-.47.44-2.75,0-5.51,0-8.27,0-.34,0-.45.08-.45.44,0,3.41,0,6.82,0,10.23a5.32,5.32,0,0,0,.73,2.94,3.51,3.51,0,0,0,2.88,1.67A7.41,7.41,0,0,0,207.84,56c.35-.22.47-.14.67.17.48.78.93,1.59,1.49,2.33Z"
          />
          <path className="cls-2" d="M117.51,69.43v.1h-3.77l0-.11Z" />
          <path
            className="cls-3"
            d="M73.77,41.27C77.1,36.72,86,36.88,89,42.34a11.35,11.35,0,0,1,1.29-1.7,8.68,8.68,0,0,1,5.22-2.5,10.37,10.37,0,0,1,6.25.93,7.92,7.92,0,0,1,4.39,6.33,10.85,10.85,0,0,1,.09,1.62c0,4.26,0,8.53,0,12.79,0,.43-.12.51-.52.5-1,0-1.91,0-2.87,0-.35,0-.41-.11-.41-.43,0-4,0-8.07,0-12.11A6.51,6.51,0,0,0,101,43.44a5.29,5.29,0,0,0-3.72-1.79A6.54,6.54,0,0,0,92,43.26a6.28,6.28,0,0,0-2,4.84Q90,54,90,59.82c0,.4-.1.5-.49.49-.94,0-1.88,0-2.82,0-.42,0-.48-.13-.48-.5,0-3.84,0-7.67,0-11.51A8.68,8.68,0,0,0,85.71,45a5.16,5.16,0,0,0-4.62-3.34,6.61,6.61,0,0,0-5.16,1.48,6.32,6.32,0,0,0-2.13,5.11c0,3.86,0,7.73,0,11.59,0,.37-.08.49-.47.48q-1.45,0-2.91,0c-.32,0-.4-.08-.4-.4q0-6.55,0-13.1c0-2.61,0-5.22,0-7.83,0-.45.13-.53.54-.52.94,0,1.89,0,2.83,0,.31,0,.39.09.38.38C73.76,39.62,73.77,40.39,73.77,41.27Z"
          />
          <path
            className="cls-3"
            d="M117.51,69.43h-3.74V69q0-15,0-30.07c0-.37.07-.51.48-.5,1,0,2,0,3,0,.27,0,.34.07.34.33,0,.82,0,1.64,0,2.56a10.44,10.44,0,0,1,2.24-1.91,11.06,11.06,0,0,1,12.8,1.39,11.5,11.5,0,0,1,3.49,9.9,10.87,10.87,0,0,1-3,6.76,10.34,10.34,0,0,1-6.81,3.21,10.5,10.5,0,0,1-6.63-1.49,10.2,10.2,0,0,1-2.06-1.82Zm7.26-27.84a7.19,7.19,0,0,0-6,2.92,8.18,8.18,0,0,0-1.51,5.14c.08,3.66,2.38,7,6.46,7.51a7.11,7.11,0,0,0,6.83-2.71,8.23,8.23,0,0,0,1.59-6.08A7.35,7.35,0,0,0,124.77,41.59Z"
          />
          <path
            className="cls-3"
            d="M158.85,41.39c0-.93,0-1.75,0-2.58,0-.25,0-.35.33-.34h3.13c.28,0,.32.09.32.34V60c0,.24-.05.33-.3.32h-3.17c-.25,0-.31-.08-.31-.32,0-.82,0-1.64,0-2.44l-.76.67a9.87,9.87,0,0,1-7.28,2.54,10.5,10.5,0,0,1-7.48-3.27,11,11,0,0,1-3-6.39,11.7,11.7,0,0,1,1.78-8.25,10.5,10.5,0,0,1,10.11-4.76A9.37,9.37,0,0,1,158.85,41.39Zm-7.28,15.83A7.1,7.1,0,0,0,157,55a8.09,8.09,0,0,0,2-6.52,7.28,7.28,0,0,0-7-6.86,7,7,0,0,0-7,3.89,8.43,8.43,0,0,0-.86,5A7.37,7.37,0,0,0,151.57,57.22Z"
          />
          <path className="cls-4" d="M30,54.37a7.41,7.41,0,1,1,7.41,7.42A7.41,7.41,0,0,1,30,54.37Z" />
          <path className="cls-5" d="M37.42,20.47A7.39,7.39,0,1,1,30,27.79,7.37,7.37,0,0,1,37.42,20.47Z" />
          <path
            className="cls-3"
            d="M179.66,60.76a11.13,11.13,0,0,1-8.19-3.32,10.67,10.67,0,0,1-2.9-5.93A12,12,0,0,1,170,43.23a10.41,10.41,0,0,1,7.62-5,11.78,11.78,0,0,1,7,.86,9.6,9.6,0,0,1,3,2.18c.18.19.18.3,0,.48-.62.63-1.24,1.27-1.82,1.93-.29.34-.45.36-.81,0a7.6,7.6,0,0,0-5.87-2.08,7,7,0,0,0-6.47,5.12,8.22,8.22,0,0,0,1.63,8.2,7.46,7.46,0,0,0,8.7,1.54A9.15,9.15,0,0,0,185.26,55c.2-.18.3-.21.49,0,.61.72,1.24,1.42,1.88,2.11.19.19.2.31,0,.51a10.34,10.34,0,0,1-6,3A13.31,13.31,0,0,1,179.66,60.76Z"
          />
          <path
            className="cls-2"
            d="M44.12,48.64a9.24,9.24,0,0,0-1.37-1.29,8.8,8.8,0,0,0-11,.24c-.28.24-.56.49-.82.76s-.31.25-.45-.08a7.44,7.44,0,0,1,1.72-8,7.43,7.43,0,0,1,11.64,1.69,7.25,7.25,0,0,1,.35,6.54A1.08,1.08,0,0,1,44.12,48.64Z"
          />
          <path
            className="cls-3"
            d="M62.34,49.42q0,5.22,0,10.43c0,.34-.06.47-.43.46-1,0-2,0-3,0-.29,0-.36-.08-.36-.36q0-10.55,0-21.08c0-.34.11-.4.42-.39,1,0,2,0,3,0,.36,0,.42.11.42.44Q62.34,44.15,62.34,49.42Z"
          />
          <path
            className="cls-3"
            d="M57.79,30.81a2.6,2.6,0,0,1,2.64-2.66,2.63,2.63,0,0,1,2.68,2.72,2.59,2.59,0,0,1-2.7,2.59A2.54,2.54,0,0,1,57.79,30.81Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Svg
