import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#459B77" width={200} height={200} />
      <g>
        <path
          fill="#F9F1E3"
          d="M40,66.7c0.3,0.3,0.7,0.6,1,0.9C53.7,80.2,66.3,92.9,79,105.5c0.3,0.3,0.6,0.7,1.3,1.3 c0.2-0.4,0.3-0.8,0.6-1.1c21.6-21.6,43.2-43.1,64.8-64.7c0.7-0.7,1.3-0.9,2.3-0.9c3.5,0.1,7.1,0,10.6,0c0.4,0,0.8,0,1.4,0 c0,0.5,0,1,0,1.4c0,3.5,0,7.1,0,10.6c0,0.9-0.3,1.5-0.9,2.1c-8.3,8.3-16.6,16.5-24.9,24.8c-0.3,0.3-0.8,0.5-1.3,0.9 c0.6,0.6,0.9,1,1.2,1.3c8.3,8.3,16.5,16.6,24.9,24.8c0.7,0.7,0.9,1.3,0.9,2.3c0,7.9,0,15.8,0,23.7c0,0.4,0,0.8,0,1.3 c-13.4-13.4-26.6-26.6-39.9-39.9c-0.5,0.5-0.8,0.8-1.1,1.1c-6.4,6.4-12.8,12.8-19.2,19.2c-15.2,15.2-30.5,30.4-45.7,45.6 c-0.3,0.3-0.6,0.5-0.9,0.8c-4.4,0-8.8,0-13.2,0c0-4.5,0-9,0-13.4c0.2-0.2,0.5-0.3,0.6-0.5c8.4-8.4,16.9-16.9,25.3-25.3 c0.2-0.2,0.4-0.5,0.6-0.7c-0.3-0.3-0.5-0.6-0.8-0.8c-8.4-8.4-16.7-16.7-25.1-25.1c-0.2-0.2-0.5-0.4-0.7-0.6 C40,84.4,40,75.5,40,66.7z"
        />
        <path
          fill="#F9F1E3"
          d="M40,40c8.4,0,16.9,0,25.3,0c0.8,0,1.4,0.2,2,0.8c3.9,3.9,7.8,7.8,11.7,11.8c0.3,0.3,0.5,0.7,0.9,1.1 c0.5-0.4,0.8-0.7,1.1-1c4-4,8-8,12-12c0.4-0.4,1-0.7,1.5-0.7c8.3,0,16.7,0,25,0c0.2,0,0.3,0,0.2,0c-13.2,13.2-26.5,26.5-40.1,40.1 c-0.1-0.1-0.2-0.6-0.6-0.9C66.3,66.5,53.7,53.8,41,41.1c-0.3-0.3-0.6-0.6-1-0.9C40,40.2,40,40.1,40,40z"
        />
        <path
          fill="#F9F1E3"
          d="M133.4,160c0-0.1,0-0.2,0-0.3c8.8,0,17.6,0,26.4,0c0,0.1,0,0.1,0,0.2C151,160,142.2,160,133.4,160z"
        />
        <path
          fill="#F9F1E3"
          d="M106.3,159.8c0,0.1,0,0.1,0,0.2c-8.7,0-17.4,0-26.2,0c0-0.1,0-0.1,0-0.2C88.8,159.8,97.5,159.8,106.3,159.8 z"
        />
        <path
          fill="#F9F1E3"
          d="M40,159.8c4.4,0,8.8,0,13.2,0c0,0.1,0,0.1,0,0.2c-4.1,0-8.3,0-12.4,0C40.5,160,40.3,159.8,40,159.8z"
        />
        <path
          fill="#F9F1E3"
          d="M106.3,159.8c-8.7,0-17.5,0-26.2,0c0.3-0.4,0.5-0.8,0.8-1.1c12.7-12.7,25.4-25.4,38.1-38.1 c0.3-0.3,0.6-0.5,0.9-0.9c0.3,0.3,0.6,0.5,0.9,0.8c12.8,12.8,25.6,25.6,38.4,38.4c0.2,0.2,0.4,0.5,0.6,0.8c-8.8,0-17.6,0-26.4,0 c-4.5-4.4-9-8.9-13.5-13.4C115.3,150.8,110.8,155.3,106.3,159.8z"
        />
      </g>
    </svg>
  )
}

export default Svg
