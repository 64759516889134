import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#FAFAFA" width={200} height={200} />
      <g>
        <defs>
          <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x={25} y={25.7} width={150} height={148.5}>
            <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
          </filter>
        </defs>
        <mask maskUnits="userSpaceOnUse" x={25} y={25.7} width={150} height={148.5} id="mask-2_11_">
          <g filter="url(#Adobe_OpacityMaskFilter)">
            <polygon
              id="path-1_11_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              points="25.1,25.7 892.1,25.7 892.1,174.3  25.1,174.3  "
            />
          </g>
        </mask>
        <path
          id="Fill-10_2_"
          mask="url(#mask-2_11_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFE"
          d="M128.7,174.3 c25.4,0.1,46.1-20.4,46.3-45.8V71.5c-0.1-25.4-20.8-45.9-46.3-45.8H71.3C45.8,25.7,25.1,46.2,25,71.5v56.9 c0.1,25.4,20.8,45.9,46.3,45.8H128.7"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_1_"
            filterUnits="userSpaceOnUse"
            x={69.5}
            y={61.3}
            width={61}
            height={80.9}
          >
            <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
          </filter>
        </defs>
        <mask maskUnits="userSpaceOnUse" x={69.5} y={61.3} width={61} height={80.9} id="mask-2_10_">
          <g filter="url(#Adobe_OpacityMaskFilter_1_)">
            <polygon
              id="path-1_10_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              points="25.1,25.7 892.1,25.7 892.1,174.3  25.1,174.3  "
            />
          </g>
        </mask>
        <path
          id="Fill-11_2_"
          mask="url(#mask-2_10_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#0A0B09"
          d="M108.2,79.3v20.6 c0,1.1,0.5,2.2,1.3,3c0.8,0.8,1.9,1.2,3,1.2h13.8c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.9,1.2-3V86.2c0-1.1-0.4-2.2-1.2-3 c-0.8-0.8-1.9-1.2-3-1.2h-9.7v-2.7c0.1-5.3,4.4-9.5,9.7-9.5c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2c-4.8,0-9.4,1.8-12.8,5.2 C110.1,69.9,108.2,74.5,108.2,79.3L108.2,79.3z M69.5,79.3v20.6c0,1.1,0.4,2.2,1.2,3c0.8,0.8,1.9,1.2,3,1.2h13.8 c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.9,1.3-3V86.2c0-1.1-0.4-2.2-1.2-3c-0.8-0.8-1.9-1.2-3-1.2H78v-2.7c0-5.3,4.3-9.5,9.6-9.5 c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2c-4.8,0-9.4,1.8-12.8,5.2C71.4,69.9,69.5,74.5,69.5,79.3L69.5,79.3z M74.6,123.4 c-0.7,0-1.4,0.4-1.7,1c-0.4,0.6-0.3,1.4,0,2c0.4,0.6,9.3,15.8,27.2,15.8s26.7-15.2,27.1-15.8c0.4-0.6,0.4-1.4,0-2 c-0.4-0.6-1-1-1.7-1H74.6L74.6,123.4z"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_2_"
            filterUnits="userSpaceOnUse"
            x={25}
            y={25.7}
            width={150}
            height={148.5}
          >
            <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
          </filter>
        </defs>
        <mask maskUnits="userSpaceOnUse" x={25} y={25.7} width={150} height={148.5} id="mask-2_9_">
          <g filter="url(#Adobe_OpacityMaskFilter_2_)">
            <polygon
              id="path-1_9_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              points="25.1,25.7 892.1,25.7 892.1,174.3  25.1,174.3  "
            />
          </g>
        </mask>
        <path
          id="Fill-12_2_"
          mask="url(#mask-2_9_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FF5551"
          d="M71.3,25.7 C45.8,25.7,25.1,46.2,25,71.5v56.9c0.1,25.4,20.8,45.9,46.3,45.8h39L84.6,156H71.3C56,156,43.5,143.7,43.5,128.5V71.5 C43.6,56.3,56,44,71.3,44h57.5c15.3,0,27.7,12.3,27.8,27.5v56.9c-0.1,15.2-12.5,27.5-27.8,27.5h-10.9v18.3h10.9 c25.4,0.1,46.1-20.4,46.3-45.8V71.5c-0.1-25.4-20.8-45.9-46.3-45.8H71.3"
        />
      </g>
    </svg>
  )
}

export default Svg
