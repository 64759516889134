import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#202BFF" width={200} height={200} />
      <g id="Page-1">
        <g id="siteship_landing" transform="translate(-165.000000, -43.000000)">
          <g id="hero-_x2B_-domain">
            <g id="logo" transform="translate(165.000000, 43.000000)">
              <g id="Group-46" transform="translate(12.000000, 16.000000)">
                <path
                  id="Fill-496"
                  fill="#FFFFFF"
                  d="M108,67.6c-1.1,0-1.9-0.5-2.4-1.4c-0.8-1.3-0.2-3,1-3.6L139,45.8H68.6L27,67.4 c-1.4,0.6-3,0.2-3.8-1.2c-0.5-1.3-0.1-3,1.3-3.6l42.3-21.9c0.4-0.1,0.6-0.2,1.1-0.2h82.4c1.1,0,2.3,0.8,2.7,2 c0.2,1.2-0.3,2.5-1.5,3l-42.3,21.8C109,67.6,108.5,67.6,108,67.6"
                />
                <path
                  id="Fill-497"
                  fill="#FFFFFF"
                  d="M43.9,67.6H25.6c-1.4,0-2.5-1.2-2.5-2.7c0-1.5,1.1-2.7,2.5-2.7h18.3 c1.4,0,2.7,1.2,2.7,2.7C46.7,66.4,45.3,67.6,43.9,67.6"
                />
                <path
                  id="Fill-498"
                  fill="#FFFFFF"
                  d="M129,45.8H88c-1.4,0-2.7-1.2-2.7-2.7c0-1.4,1.3-2.6,2.7-2.6H129c1.7,0,2.7,1.2,2.7,2.6 C131.8,44.6,130.7,45.8,129,45.8"
                />
                <path
                  id="Fill-499"
                  fill="#FFFFFF"
                  d="M108,81.5H86.7c-1.7,0-2.7-1.2-2.7-2.7c0-1.4,1.1-2.6,2.7-2.6h20.6l41.6-21.5 c1.5-0.6,3-0.2,3.8,1.2c0.5,1.3,0.2,3-1.3,3.6l-42.3,21.7C109,81.4,108.5,81.5,108,81.5"
                />
                <path
                  id="Fill-500"
                  fill="#FFFFFF"
                  d="M46.7,81.5H25.6c-1.4,0-2.5-1.2-2.5-2.7c0-1.4,1.1-2.6,2.5-2.6h21.1 c1.4,0,2.7,1.2,2.7,2.6C49.4,80.3,48.1,81.5,46.7,81.5"
                />
                <path
                  id="Fill-501"
                  fill="#FFFFFF"
                  d="M108,95.3c-1.1,0-1.9-0.3-2.4-1.4c-0.8-1.2-0.2-3,1-3.6l42.3-21.8c1.5-0.8,3-0.2,3.8,1.2 c0.5,1.3,0.2,2.9-1.3,3.6l-42.3,21.9C109,95.2,108.5,95.3,108,95.3"
                />
                <path
                  id="Fill-502"
                  fill="#FFFFFF"
                  d="M46.7,95.3H25.6c-1.4,0-2.5-1.1-2.5-2.5c0-1.6,1.1-2.7,2.5-2.7h21.1 c1.4,0,2.7,1.1,2.7,2.7C49.4,94.2,48.1,95.3,46.7,95.3"
                />
                <path
                  id="Fill-503"
                  fill="#FFFFFF"
                  d="M107.5,95.3H86.7c-1.7,0-2.7-1.1-2.7-2.5c0-1.6,1.1-2.7,2.7-2.7h20.8 c1.4,0,2.7,1.1,2.7,2.7C110.3,94.2,109,95.3,107.5,95.3"
                />
                <path
                  id="Fill-504"
                  fill="#FFFFFF"
                  d="M108,109.3c-1.1,0-1.9-0.6-2.4-1.6c-0.8-1.2-0.2-2.8,1-3.5l42.3-21.8 c1.5-0.7,3-0.1,3.8,1.2c0.5,1.3,0.2,2.9-1.3,3.7L109.1,109C109,109.2,108.5,109.3,108,109.3"
                />
                <path
                  id="Fill-505"
                  fill="#FFFFFF"
                  d="M46.7,109.3H25.6c-1.4,0-2.5-1.3-2.5-2.7c0-1.5,1.1-2.7,2.5-2.7h21.1 c1.4,0,2.7,1.2,2.7,2.7C49.4,108,48.1,109.3,46.7,109.3"
                />
                <path
                  id="Fill-506"
                  fill="#FFFFFF"
                  d="M107.5,109.3H86.7c-1.7,0-2.7-1.3-2.7-2.7c0-1.5,1.1-2.7,2.7-2.7h20.8 c1.4,0,2.7,1.2,2.7,2.7C110.3,108,109,109.3,107.5,109.3"
                />
                <path
                  id="Fill-507"
                  fill="#FFFFFF"
                  d="M108,123.1c-1.1,0-1.9-0.6-2.4-1.4c-0.8-1.5-0.2-3,1-3.8l42.3-21.7 c1.5-0.8,3-0.2,3.8,1.3c0.5,1.2,0.2,2.8-1.3,3.5l-42.3,21.7C109,123,108.5,123.1,108,123.1"
                />
                <path
                  id="Fill-508"
                  fill="#FFFFFF"
                  d="M46.7,123.1H25.6c-1.4,0-2.5-1.1-2.5-2.7c0-1.4,1.1-2.7,2.5-2.7h21.1 c1.4,0,2.7,1.3,2.7,2.7C49.4,122,48.1,123.1,46.7,123.1"
                />
                <path
                  id="Fill-509"
                  fill="#FFFFFF"
                  d="M107.5,123.1H86.7c-1.7,0-2.7-1.1-2.7-2.7c0-1.4,1.1-2.7,2.7-2.7h20.8 c1.4,0,2.7,1.3,2.7,2.7C110.3,122,109,123.1,107.5,123.1"
                />
                <path
                  id="Fill-510"
                  fill="#FFFFFF"
                  d="M86.7,127.5H46.7c-1.5,0-2.7-1.1-2.7-2.7V65c0-1.5,1.2-2.7,2.7-2.7 c1.4,0,2.7,1.2,2.7,2.7v57.3H84V65c0-1.5,1.1-2.7,2.7-2.7c1.4,0,2.6,1.2,2.6,2.7v59.8C89.3,126.4,88.1,127.5,86.7,127.5"
                />
                <path
                  id="Fill-511"
                  fill="#FFFFFF"
                  d="M107.1,67.6H89.1c-1.7,0-2.7-1.2-2.7-2.7c0-1.5,1.1-2.7,2.7-2.7h18.1 c1.7,0,2.7,1.2,2.7,2.7C109.9,66.4,108.8,67.6,107.1,67.6"
                />
                <path
                  id="Fill-512"
                  fill="#FFFFFF"
                  d="M107.1,67.6H89.1c-1.7,0-2.7-1.2-2.7-2.7c0-1.5,1.1-2.7,2.7-2.7h18.1 c1.7,0,2.7,1.2,2.7,2.7C109.9,66.4,108.8,67.6,107.1,67.6"
                />
                <path
                  id="Fill-513"
                  fill="#FFFFFF"
                  d="M76.7,112.8c-10.9,0-19.5-8.6-19.5-19.5c0-10.7,8.7-19.5,19.5-19.5 c1.4,0,2.7,1.2,2.7,2.7c0,1.5-1.3,2.7-2.7,2.7c-7.8,0-14.3,6.4-14.3,14c0,7.8,6.5,14.2,14.3,14.2c1.4,0,2.7,1.2,2.7,2.6 C79.5,111.8,78.1,112.8,76.7,112.8"
                />
                <path
                  id="Fill-514"
                  fill="#FFFFFF"
                  d="M71.9,92.3H55.4c-1.4,0-2.7-1.3-2.7-2.7c0-1.5,1.3-2.7,2.7-2.7h16.5 c1.4,0,2.7,1.2,2.7,2.7C74.6,91,73.3,92.3,71.9,92.3"
                />
                <path
                  id="Fill-515"
                  fill="#FFFFFF"
                  d="M71.9,100H55.4c-1.4,0-2.7-1.3-2.7-2.7c0-1.7,1.3-2.7,2.7-2.7h16.5 c1.4,0,2.7,1.1,2.7,2.7C74.6,98.7,73.3,100,71.9,100"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Svg
