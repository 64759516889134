import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#F80000" width={200} height={200} />
      <g>
        <path
          fill="#FFFFFF"
          d="M86.6,103.1h10.2l-5.4-8.7l-9.9,15.7H77l12-18.9c0.5-0.8,1.4-1.2,2.4-1.2c0.9,0,1.8,0.4,2.3,1.2l12.1,18.9 h-4.5l-2.1-3.5H88.9L86.6,103.1"
        />
        <path
          fill="#FFFFFF"
          d="M133.4,106.4V90.2h-3.8v18c0,0.5,0.2,1,0.6,1.3c0.4,0.4,0.9,0.6,1.4,0.6H149l2.3-3.5L133.4,106.4"
        />
        <path
          fill="#FFFFFF"
          d="M70.2,103.6c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7H53.5v19.9h3.8V93.7h12.6c1.8,0,3.2,1.4,3.2,3.2 c0,1.8-1.4,3.2-3.2,3.2H59.1l11.4,10H76l-7.7-6.4L70.2,103.6"
        />
        <path
          fill="#FFFFFF"
          d="M29.9,110.1c-5.5,0-9.9-4.4-9.9-9.9s4.4-9.9,9.9-9.9h11.5c5.5,0,9.9,4.4,9.9,9.9s-4.4,9.9-9.9,9.9H29.9  M41.2,106.6c3.6,0,6.4-2.9,6.4-6.4c0-3.6-2.9-6.4-6.4-6.4h-11c-3.5,0-6.4,2.9-6.4,6.4s2.9,6.4,6.4,6.4H41.2"
        />
        <path
          fill="#FFFFFF"
          d="M113.8,110.1c-5.5,0-10-4.4-10-9.9s4.5-9.9,10-9.9h13.7l-2.3,3.5H114c-3.6,0-6.5,2.9-6.5,6.4 s2.9,6.4,6.5,6.4h13.8l-2.2,3.5H113.8"
        />
        <path
          fill="#FFFFFF"
          d="M160.5,106.6c-2.9,0-5.4-2-6.2-4.7h16.4l2.2-3.5h-18.6c0.8-2.7,3.3-4.7,6.2-4.7h11.2l2.2-3.5h-13.7 c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9H172l2.2-3.5h-13.7"
        />
        <path
          fill="#FFFFFF"
          d="M176,92.2c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8S176,93.2,176,92.2 M177.8,94.6 c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2C175.6,93.6,176.5,94.6,177.8,94.6 M177.6,90.9c0.3,0,0.5,0,0.6,0.1 c0.5,0.1,0.5,0.6,0.5,0.7c0,0,0,0.1,0,0.2c0,0.1-0.1,0.3-0.3,0.4c0,0,0,0-0.1,0.1l0.6,1h-0.6l-0.5-1h-0.3v1H177v-2.5H177.6  M177.7,92.1c0.2,0,0.3,0,0.4-0.2c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.5-0.1h-0.1v0.7h0.3"
        />
      </g>
    </svg>
  )
}

export default Svg
