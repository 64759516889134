import * as React from "react"

function Svg(props) {
  return (
    <svg viewBox="0 0 210 210" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Help Scout logo circle blue</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M38.963164,33.4094692 L6.99338833,66.8189368 C2.99716813,62.6427528 0.499529604,56.9004985 3.55271368e-15,50.1142041 C3.55271368e-15,43.8499253 2.99716693,37.5856512 6.99338833,33.4094692 L39.4626892,0 C43.4589119,4.17618317 45.9565504,10.4404586 45.9565504,16.704734 C45.9565504,22.9690106 42.9593867,29.2332848 38.963164,33.4094692 Z M53.0267868,79.6687274 L85.3478748,46.2592639 C89.3880167,50.9574679 91.9131018,56.6997219 91.9131018,62.9640056 C91.9131018,69.2282793 88.8829981,75.4925531 84.8428654,79.6687373 L52.5217723,113.078201 C48.4816356,108.902012 45.9565504,102.637743 45.9565504,96.3734638 C45.9565504,90.1091854 48.9866496,83.8449064 53.0267868,79.6687274 Z M52.2347626,33.4094602 L61.7776558,23.6436158 L85.383761,0 C89.401816,4.11193403 91.9131028,10.2798358 91.9131028,16.4477375 C91.9131028,22.6156405 88.8995566,28.783541 84.8815016,32.8954763 L61.7776603,56.5390981 L52.2347671,66.3049447 L38.673819,80.1827225 L29.1309263,89.9485645 L6.52934443,113.078195 C2.51128688,108.966255 -1.42108547e-14,102.798359 -1.42108547e-14,96.6304536 C-1.42108547e-14,90.4625485 3.01354496,84.2946429 7.03160376,80.1827127 L29.6331857,57.0530822 L38.6738145,47.2872397 L52.2347626,33.4094602 Z"
          id="path-1"
        />
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Visual-Elements-/-Logos" transform="translate(-898.000000, -2390.000000)">
          <g id="Help-Scout-logo-circle-blue" transform="translate(898.000000, 2390.000000)">
            <circle id="Oval" fill="#1292EE" cx="105" cy="105" r="105" />
            <g id="Logos/1.-Help-Scout/Mark" transform="translate(59.000000, 49.000000)">
              <g id="Color-Mask">
                <mask id="mask-2" fill="white">
                  <use href="#path-1" />
                </mask>
                <g id="Icon" />
                <g id="Colors/_Default/White" mask="url(#mask-2)" fill="#FFFFFF">
                  <rect id="Swatch" x="0" y="0" width="92.0311863" height="113.038305" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Svg
