import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#448EE1" width={200} height={200} />
      <g>
        <path
          fill="#FFFFFF"
          d="M121.1,112.2c-8.4-2.3-11.5-4.7-11.5-9.5c0-4.7,3.6-8.1,10.7-8.1c7.4,0.2,14.6,2.7,20.4,7.4V87 c-6.3-3.8-13.5-5.8-20.8-5.6c-14.7,0-25.8,7.4-25.8,22.1c0,12.6,6.4,18.6,21.5,22.1c9.4,2.5,13.6,4.6,13.6,10.5 c0,5.9-4.7,8.2-12,8.2c-8-0.3-15.8-3.2-22.1-8.2v15.4c6.8,4,14.5,6,22.4,5.8c15.8,0,27.4-6.9,27.4-22.1 C144.9,122.1,137.5,115.9,121.1,112.2z"
        />
        <path
          fill="#FFFFFF"
          d="M99.2,84.2c0.4-0.3,0.8-0.6,1.2-0.9H82.6V69.4c0-8.8,3.9-12.3,10.8-12.3c4.5,0,8.9,1.4,12.5,4V46.5 c-4.3-2.6-9.3-3.8-14.3-3.7c-15.3,0-25.1,8.8-25.1,26.8v13.7H55.1v13.5h11.3v59.1h16.1V96.7h8.7C92.5,91.7,95.2,87.4,99.2,84.2z"
        />
      </g>
    </svg>
  )
}

export default Svg
