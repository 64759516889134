import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Export"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-1{fill:#4fc3f7;}.cls-2{fill:#4285f4;}.cls-3,.cls-8{fill:#1a237e;}.cls-3,.cls-5,.cls-6,.cls-7{opacity:0.2;}.cls-3,.cls-5,.cls-6{isolation:isolate;}.cls-4{fill:#ffc107;}.cls-5,.cls-9{fill:#fff;}.cls-6{fill:#bf360c;}.cls-10{fill:#3367d6;}.cls-11{fill:url(#linear-gradient);}.cls-12{fill-opacity:0.1;fill:url(#radial-gradient);}"
          }
        </style>
        <linearGradient
          id="linear-gradient"
          x1={-2701.49}
          y1={1253.23}
          x2={-2700.98}
          y2={1252.97}
          gradientTransform="matrix(56.82, 0, 0, -110.18, 153651.06, 138188.04)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bf360c" stopOpacity={0.2} />
          <stop offset={1} stopColor="#bf360c" stopOpacity={0} />
        </linearGradient>
        <radialGradient
          id="radial-gradient"
          cx={-2738.05}
          cy={1259.25}
          r={0.66}
          gradientTransform="matrix(254.33, 0, 0, -254.33, 696402.48, 320300.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </radialGradient>
      </defs>
      <title>{"ga360-logo_200x200_square"}</title>
      <path
        className="cls-1"
        d="M127.33,127.29a38.86,38.86,0,0,1-25.5,11.3c-.58,0-1.25.09-1.83.09a12.6,12.6,0,0,1-1.84-.09,61.94,61.94,0,0,1-28.68-8,59.24,59.24,0,0,1-13.14-10A61.59,61.59,0,0,1,46.29,46.34,61.74,61.74,0,0,0,100,157.23a61.77,61.77,0,0,0,38.54-55.44A38.1,38.1,0,0,1,127.33,127.29Z"
      />
      <path
        className="cls-2"
        d="M143.66,33.11a61.8,61.8,0,0,0-87.42,0,64.22,64.22,0,0,0-10,13.14A61.79,61.79,0,0,0,42.72,100a60.35,60.35,0,0,0,13.62,20.48,63.87,63.87,0,0,0,13.14,10,61.82,61.82,0,0,0,28.68,8,39,39,0,0,1-25.5-11.3A38.26,38.26,0,0,1,61.36,100v-.09a61.51,61.51,0,0,1,8.12-30.53,59.11,59.11,0,0,1,10-13.13A61.18,61.18,0,0,1,100,42.58a62,62,0,0,1,53.8,3.57A62.18,62.18,0,0,0,143.66,33.11Z"
      />
      <path
        className="cls-3"
        d="M100,41.71A60.2,60.2,0,0,0,79.52,55.33a63.82,63.82,0,0,0-10,13.13,60.87,60.87,0,0,0-8.12,30.43v.48a61.74,61.74,0,0,1,8.12-30,59.11,59.11,0,0,1,10-13.13A61.18,61.18,0,0,1,100,42.58a62,62,0,0,1,53.8,3.57l-.87-1.45A62,62,0,0,0,100,41.71Z"
      />
      <path
        className="cls-3"
        d="M56.34,119.46A61.29,61.29,0,0,1,42.72,99,61.94,61.94,0,0,1,38.18,75.9a61.7,61.7,0,0,0,18.16,44.53,63.87,63.87,0,0,0,13.14,10,61.82,61.82,0,0,0,28.68,8,39.43,39.43,0,0,1-12-2.51,63,63,0,0,1-16.71-6.47A56.41,56.41,0,0,1,56.34,119.46Z"
      />
      <path
        className="cls-4"
        d="M166.84,56.29a64.22,64.22,0,0,0-13.14-10,62,62,0,0,0-53.8-3.58A60.5,60.5,0,0,0,79.42,56.29a64.22,64.22,0,0,0-10,13.14,61.81,61.81,0,0,0-8,30.43,38.4,38.4,0,0,1,11.3-27.24,38.83,38.83,0,0,1,25.5-11.3,22.48,22.48,0,0,1,3.67,0,62,62,0,0,1,28.69,8,59.51,59.51,0,0,1,13.14,10,61.57,61.57,0,0,1,10,74.18,59.51,59.51,0,0,0,13.14-10.05A61.56,61.56,0,0,0,166.84,56.29Z"
      />
      <path
        className="cls-5"
        d="M61.36,100.92a38.66,38.66,0,0,0,36.8,38.63c.58,0,1.26.1,1.84.1a11.23,11.23,0,0,0,1.83-.1,38.75,38.75,0,0,0,36.61-34.38c.1-1.06.19-2.22.19-3.29a38.75,38.75,0,0,1-36.8,36.71c-.58,0-1.25.09-1.83.09a12.6,12.6,0,0,1-1.84-.09,38.66,38.66,0,0,1-36.8-38.16v.49Z"
      />
      <path
        className="cls-6"
        d="M157.37,99a60.2,60.2,0,0,0-13.62-20.48,64.22,64.22,0,0,0-13.13-10.05,62,62,0,0,0-28.69-8,22.48,22.48,0,0,0-3.67,0,38.56,38.56,0,0,0-25.5,11.3,37.73,37.73,0,0,0-11.4,27.14v.58a38.35,38.35,0,0,1,11.3-26.85,38.83,38.83,0,0,1,25.5-11.3,22.48,22.48,0,0,1,3.67,0,62,62,0,0,1,28.69,8,59.51,59.51,0,0,1,13.14,10,61,61,0,0,1,18.06,43.56A60.52,60.52,0,0,0,157.37,99Z"
      />
      <path
        className="cls-6"
        d="M166.84,142.64a62.2,62.2,0,0,1-12.46,9.57c-.29.48-.49,1-.77,1.45a59.46,59.46,0,0,0,13.13-10A61.34,61.34,0,0,0,184.8,99.37,60.27,60.27,0,0,1,166.84,142.64Z"
      />
      <g className="cls-7">
        <path
          className="cls-8"
          d="M143.66,80.44A61,61,0,0,1,161.72,124a62.44,62.44,0,0,0-4.44-24,60.5,60.5,0,0,0-13.62-20.48,64.22,64.22,0,0,0-13.14-10,62,62,0,0,0-28.69-8,40,40,0,0,1,12,2.51,63.07,63.07,0,0,1,16.71,6.48A56.3,56.3,0,0,1,143.66,80.44Z"
        />
      </g>
      <g className="cls-7">
        <path
          className="cls-9"
          d="M166.84,56.29a64.22,64.22,0,0,0-13.14-10,59.47,59.47,0,0,0-10-13.14,61.8,61.8,0,0,0-87.42,0,64.22,64.22,0,0,0-10,13.14A61.75,61.75,0,0,0,15,100v.48A61.67,61.67,0,0,1,45.43,47.7a62.11,62.11,0,0,0-7.25,29.17,62.27,62.27,0,0,1,8-29.66,59.41,59.41,0,0,1,10-13.13,61.8,61.8,0,0,1,87.42,0,59.47,59.47,0,0,1,9.17,11.78A61.74,61.74,0,0,0,99.9,42.77,60.35,60.35,0,0,0,79.42,56.39a64.22,64.22,0,0,0-10,13.14,61.16,61.16,0,0,0-8,30.33c0-1.26.1-2.61.2-3.87A61.7,61.7,0,0,1,69.48,70.3a59.16,59.16,0,0,1,10-13.14,61.66,61.66,0,0,1,74.28-10,59.41,59.41,0,0,1,13.13,10A61.36,61.36,0,0,1,185,100.43,61.31,61.31,0,0,0,166.84,56.29Z"
        />
      </g>
      <path
        id="path-1"
        d="M157.37,100a60.2,60.2,0,0,0-13.62-20.48,63.9,63.9,0,0,0-13.13-10,62,62,0,0,0-28.69-8A38.58,38.58,0,0,1,138.64,98c0,.68.09,1.26.09,1.93a13.79,13.79,0,0,1-.09,1.93,61.34,61.34,0,0,1-8,28.69,61.63,61.63,0,0,1-84.23,23.18,59.51,59.51,0,0,0,10,13.14,61.79,61.79,0,0,0,87.41,0,64.28,64.28,0,0,0,10.05-13.14A62.66,62.66,0,0,0,157.37,100Z"
      />
      <path
        className="cls-10"
        d="M157.37,100a60.2,60.2,0,0,0-13.62-20.48,64.17,64.17,0,0,0-13.13-10,62,62,0,0,0-28.69-8A38.56,38.56,0,0,1,138.63,98c0,.68.1,1.26.1,1.93a12.42,12.42,0,0,1-.1,1.93,61.33,61.33,0,0,1-8,28.69,61.63,61.63,0,0,1-84.23,23.18,59.51,59.51,0,0,0,10,13.14,61.79,61.79,0,0,0,87.41,0,63.87,63.87,0,0,0,10-13.14A62.62,62.62,0,0,0,157.37,100Z"
      />
      <g className="cls-7">
        <path
          className="cls-8"
          d="M100,158.19a61.74,61.74,0,0,0,38.54-55.44c0-.67.09-1.25.09-1.93v1a61.32,61.32,0,0,1-8,28.68,61.65,61.65,0,0,1-84.23,23.19l.87,1.44A61.23,61.23,0,0,0,100,158.19Z"
        />
      </g>
      <g className="cls-7">
        <path
          className="cls-8"
          d="M47.07,154.14a61.78,61.78,0,0,0,91.47-52.35,18.32,18.32,0,0,1-.2,2.32A61.78,61.78,0,0,1,15,99.47V100a61.67,61.67,0,0,0,31.29,53.71"
        />
      </g>
      <path
        className="cls-11"
        d="M144.24,80.05a61.59,61.59,0,0,1,9.46,73.61,59.51,59.51,0,0,0,13.14-10A60.77,60.77,0,0,0,182.2,118Z"
      />
      <path
        className="cls-3"
        d="M153.7,152.69a59.47,59.47,0,0,1-10,13.14,61.83,61.83,0,0,1-96.59-11.69c-.29-.2-.58-.29-.87-.48a59.41,59.41,0,0,0,10,13.13,61.8,61.8,0,0,0,87.42,0,64.44,64.44,0,0,0,10-13.13,60.72,60.72,0,0,0,8-30.62A60.29,60.29,0,0,1,153.7,152.69Z"
      />
      <path
        className="cls-12"
        d="M166.84,56.29a64.22,64.22,0,0,0-13.14-10,59.47,59.47,0,0,0-10-13.14,61.8,61.8,0,0,0-87.42,0,64.22,64.22,0,0,0-10,13.14,61.78,61.78,0,0,0,.09,107.41,59.46,59.46,0,0,0,10.05,13.13,61.79,61.79,0,0,0,87.41,0,64.5,64.5,0,0,0,10.05-13.13,59.46,59.46,0,0,0,13.13-10A61.76,61.76,0,0,0,166.84,56.29ZM138.63,100A38.64,38.64,0,1,1,100,61.32,38.63,38.63,0,0,1,138.63,100Z"
      />
    </svg>
  )
}

export default Svg
