import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#AF7CFB" width={200} height={200} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M109.9,109.2c2.1,2,5.2,4.7,9.3,8c4.1,3.3,9.4,6.9,15.8,9.8 c3.2,1.3,6.8,2.4,10.4,2.6c1.8,0,3.7-0.1,5.4-0.6c0.9-0.2,1.7-0.6,2.6-0.9c0.8-0.4,1.7-0.8,2.4-1.4c1.5-1.1,3-2.4,4.2-4 c1.2-1.6,2.3-3.5,3.1-5.5c1.7-4,2.7-8.6,2.9-13.4c0.2-4.8-0.4-9.7-1.9-14.3c-1.5-4.5-4-8.7-7.4-11.3c-3.4-2.5-7.6-3.5-12.3-2.7 c-2.3,0.4-4.7,1.1-7.1,2.1c-1.2,0.4-2.4,1.1-3.6,1.7c-1.2,0.7-2.4,1.3-3.5,2c-4.7,2.9-9.1,6.4-13.5,10.3c-2.1,2-4.3,3.9-6.3,6 c-1,1-2.1,2.1-3.1,3.2l-3.1,3.3c-4,4.4-8.7,9-13.3,13.1c-4.7,4.2-9.8,8-15.3,11.3c-5.5,3.3-11.6,6-18.5,6.9c-3.4,0.5-7,0.4-10.5-0.5 c-3.5-0.8-6.9-2.5-9.6-4.8c-2.8-2.2-5-5-6.6-7.9c-1.7-2.9-2.9-5.9-3.7-8.9c-0.8-3-1.3-6-1.5-9c-0.2-3-0.2-5.9,0.1-8.8 c0.6-5.7,2.1-11.2,4.6-16.2c1.3-2.4,2.8-4.7,4.6-6.8c1.8-2,3.9-3.8,6.2-5.1c2.3-1.4,4.8-2.2,7.2-2.7c2.5-0.5,4.9-0.4,7.2-0.2 c4.6,0.6,8.5,2.3,11.9,4.1c6.8,3.8,11.7,8.2,15.4,12.1c3.8,3.8,6.4,7.1,8.2,9.3c1.8,2.3,2.7,3.5,2.7,3.5s-1.1-1-3.2-3 c-2.1-2-5.2-4.7-9.3-8c-4.1-3.3-9.4-6.9-15.8-9.7c-3.2-1.3-6.8-2.3-10.4-2.5c-3.6-0.1-7.3,0.7-10.4,3c-1.5,1.1-3,2.4-4.2,4.1 c-1.2,1.6-2.3,3.5-3.1,5.5c-1.7,4-2.7,8.6-2.9,13.4c-0.2,4.8,0.3,9.7,1.8,14.3c1.5,4.5,4,8.7,7.4,11.2c3.4,2.5,7.6,3.4,12.3,2.6 c0.6,0,1.2-0.3,1.8-0.4c0.6-0.1,1.2-0.3,1.8-0.5l1.8-0.6l1.8-0.7c0.6-0.2,1.2-0.5,1.8-0.8l1.8-0.9c1.2-0.7,2.4-1.3,3.5-2 c4.7-2.9,9.1-6.4,13.5-10.3c4.4-3.9,8.3-7.9,12.5-12.5l3.2-3.3c1.1-1.1,2.2-2.3,3.3-3.4c2.2-2.2,4.5-4.3,6.9-6.5 c4.7-4.2,9.8-8,15.3-11.3c5.5-3.3,11.7-5.9,18.5-6.8c3.4-0.5,7-0.3,10.5,0.6c3.5,0.9,6.8,2.6,9.6,4.8c2.8,2.3,4.9,5,6.6,7.8 c1.7,2.9,2.9,5.9,3.7,8.9c0.8,3,1.3,6,1.5,9c0.2,3,0.2,5.9-0.1,8.8c-0.6,5.7-2.1,11.3-4.6,16.2c-1.3,2.5-2.8,4.8-4.6,6.8 c-1.8,2.1-3.9,3.8-6.2,5.1c-1.1,0.7-2.4,1.2-3.6,1.7c-1.2,0.4-2.5,0.8-3.7,0.9c-2.5,0.5-4.9,0.4-7.2,0.1c-4.5-0.7-8.5-2.3-11.8-4.2 c-6.8-3.8-11.6-8.3-15.4-12.1c-3.8-3.8-6.4-7.1-8.2-9.3c-1.8-2.3-2.7-3.5-2.7-3.5S107.8,107.2,109.9,109.2z"
      />
    </svg>
  )
}

export default Svg
