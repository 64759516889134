import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#8970B2" width={200} height={200} />
      <g id="Layer_1_2_">
        <g>
          <path
            fill="#FFFFFB"
            d="M141.2,67.7h-9.9c-2.3,0-4.8,1.8-5.3,4.1l-12.6,53.8c6.6,2.1,12.4,4.4,17,6.6l14-60.2 C145.1,69.5,143.5,67.7,141.2,67.7z M114.6,84.4H105c-2.3,0-4.8,1.8-5.3,4.1l-7.6,32.1c6.4,1.1,12.4,2.3,17.6,3.7l8.2-35.7 C118.5,86.3,116.9,84.4,114.6,84.4z M64.7,105h-9.9c-2.3,0-4.8,1.8-5.3,4.1l-2.1,8.9c1.6,0,3-0.2,4.8-0.2c4.8,0,9.4,0,13.7,0.2 l2.1-8.9C68.5,107.1,67.2,105,64.7,105z M90.3,94h-9.9c-2.3,0-4.8,1.8-5.3,4.1l-4.8,20.2c6.4,0.5,12.4,0.9,18.1,1.6l5-22 C94.2,95.9,92.6,94,90.3,94z"
          />
        </g>
      </g>
      <path
        fill="#FFFFFB"
        d="M100,182.5c-45.5,0-82.5-37-82.5-82.5s37-82.5,82.5-82.5s82.5,37,82.5,82.5S145.5,182.5,100,182.5z  M100,28.5c-39.4,0-71.5,32.1-71.5,71.5s32.1,71.5,71.5,71.5s71.5-32.1,71.5-71.5S139.4,28.5,100,28.5z"
      />
    </svg>
  )
}

export default Svg
