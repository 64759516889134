import * as React from "react"

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 198.8 198.8" {...props}>
      <defs>
        <style>{".cls-1{fill:#84c011;}.cls-2{fill:#646b71;}"}</style>
      </defs>
      <title>{"mambu-logo_200x200_square"}</title>
      <g id="Guidelines">
        <g id="lTm1To.tif">
          <path
            className="cls-1"
            d="M45.42,68.89a22.45,22.45,0,0,1,3.72-10c4.08-5.67,12-5.73,16.59-.79a38.3,38.3,0,0,1,5.89,9,51.71,51.71,0,0,0,6.71,10.5c4.78,5.53,10.48,9.68,17.58,11.66a20.7,20.7,0,0,0,13.26-.36c6.17-2.37,9.91-7.11,12.37-13,2.13-5.13,2.73-10.59,3.53-16,.57-3.91,1-7.84,1.56-11.74A18.36,18.36,0,0,1,129.5,40a7,7,0,0,1,7.59-3c3.52.91,6,3.25,8,6.11,5.5,7.87,7.67,16.9,8.78,26.24a40,40,0,0,1-.68,11.28A56.06,56.06,0,0,1,147,98.87a53.36,53.36,0,0,1-25.79,23.65,52.55,52.55,0,0,1-17.72,4.38,73.85,73.85,0,0,1-11.12-.4,47.84,47.84,0,0,1-16.87-5.06,55,55,0,0,1-15.64-11.59,53.89,53.89,0,0,1-9-12.76,52.17,52.17,0,0,1-5.22-16.48A58.41,58.41,0,0,1,45.42,68.89Z"
          />
          <path
            className="cls-1"
            d="M105,18.64a52,52,0,0,1,18.21,5c2.18,1,4.26,2.25,6.38,3.4a6.71,6.71,0,0,1,.87.72c-1.43,0-2.53,0-3.63,0a10.91,10.91,0,0,0-7,2.32,20.29,20.29,0,0,0-5.78,7.69c-1.23,2.83-2.22,5.77-3.6,8.52a11.17,11.17,0,0,1-8.27,6.38A15.66,15.66,0,0,1,92.26,51c-3.14-1.6-6.1-3.55-9.08-5.44-4.15-2.64-8.41-5-13.4-5.51a19.54,19.54,0,0,0-10.47,2.2c-1.34.64-2.69,1.28-4.08,1.81a6.94,6.94,0,0,1-1.9.16l-.16-.32c1.23-1.74,2.39-3.55,3.71-5.21a57.27,57.27,0,0,1,4.17-4.64A53.45,53.45,0,0,1,79.63,21.91a46.43,46.43,0,0,1,13.74-3.28A76.74,76.74,0,0,1,105,18.64Z"
          />
        </g>
        <g id="ojH43F">
          <path
            className="cls-2"
            d="M26.26,183.12c-4.53-10.44-9-20.64-13.37-30.85h-.18c-.08,1.21-.18,2.41-.23,3.62-.18,4.88-.33,9.76-.52,14.64-.14,3.6-.32,7.21-.46,10.81,0,.39-.05,1.11-.05,1.11s-.7,0-1.12,0c-.82-.05-1.64,0-2.47,0,0-1.73-.06-3.36,0-5,.18-4.13.42-8.25.61-12.38.33-7,.62-14,1-20.94,0-.6.1-2.46.1-2.46s2.59.54,3.16,1.61c.87,1.66,1.7,3.34,2.44,5.06Q20.64,161.19,26.07,174c0,.06.1.1.24.23a3.49,3.49,0,0,0,.32-.5c1.47-3.49,2.92-7,4.39-10.48q3.14-7.47,6.31-14.92c.59-1.38,1.15-2.78,1.86-4.1a6.33,6.33,0,0,1,1.7-2.14,9.56,9.56,0,0,1,1.94-.68s.1,1.54.15,2.26c.21,3.67.38,7.35.56,11q.55,11.53,1.09,23.07c.07,1.49,0,3,0,4.51a.72.72,0,0,1-.27.1c-.94,0-2-.06-3.06-.06,0-.81-.15-1.73-.2-2.61-.22-4-.4-8-.58-11.93-.24-5.17-.47-10.35-.9-15.57Z"
          />
          <path
            className="cls-2"
            d="M119.28,152.05c-4.5,10.42-8.91,20.63-13.45,31.11L92.36,152c-.1,1.6-.19,2.92-.25,4.25-.26,5.89-.5,11.78-.76,17.67-.12,2.56-.29,5.1-.4,7.66,0,.3,0,.84,0,.84l-.8.05c-1.36.1-2.66.07-2.66.07s-.06-1.22,0-2.59c.33-7.42.71-14.85,1.06-22.28.22-4.61.41-9.23.65-13.84,0-.75.23-1.82.23-1.82a6.82,6.82,0,0,1,2.22.66A4.42,4.42,0,0,1,93,144.47c1.87,4.22,3.66,8.47,5.48,12.71,1.49,3.49,3,7,4.47,10.46.81,1.93,1.59,3.88,2.39,5.82.11.27.24.53.46,1l2.42-5.72c1.52-3.56,3-7.1,4.56-10.66,1.82-4.28,3.64-8.56,5.42-12.86.92-2.21,1.74-2.93,3.38-2.91l.84,0s.08.65.09.9q.28,7.15.63,14.3.52,11.09,1.08,22.17c0,.11,0,.23,0,.34,0,1.22,0,2.32,0,2.32l-2.38.1-1.24.05s-.06-1-.08-1.49c-.16-3.6-.33-7.2-.47-10.81q-.37-8.78-.72-17.56A3.42,3.42,0,0,0,119.28,152.05Z"
          />
          <path
            className="cls-2"
            d="M152.2,161.47a28.68,28.68,0,0,1,2.53,1.94c3.37,3.23,4.21,8.85,2,13.35a9.77,9.77,0,0,1-8.75,5.59c-4,.2-8.12.08-12.17.06a2.43,2.43,0,0,1-2.55-2.57q0-17.46,0-34.93a2.35,2.35,0,0,1,2.12-2.51,105.52,105.52,0,0,1,12.83.15c6.62.72,9.6,6.65,8.28,12.88a9.59,9.59,0,0,1-3.31,5.23ZM136.73,171q0,3.44,0,6.88c0,.57,0,1,.82,1,3.53,0,7.06.09,10.59-.07a6.35,6.35,0,0,0,6.1-4.53c1.61-4.66-.83-9.61-5.73-10.52-3.61-.67-7.4-.39-11.11-.46-.21,0-.62.66-.63,1C136.7,166.6,136.73,168.82,136.73,171Zm0-18.37c0,2.1,0,4.21,0,6.31,0,.78.28,1.05,1.06,1,2.63,0,5.25,0,7.88,0a8.15,8.15,0,0,0,5.55-2.14,7.15,7.15,0,0,0,2-7.62,5.66,5.66,0,0,0-5.32-4.6c-3.44-.24-6.9-.15-10.35-.21-.77,0-.81.49-.81,1.06C136.73,148.54,136.73,150.61,136.73,152.67Z"
          />
          <path
            className="cls-2"
            d="M81.16,182.38c-1.57.1-3.46.09-3.46.09a26.17,26.17,0,0,1-.85-2.76c-.82-3.62-1.66-7.23-2.43-10.86-.09-.39-.2-1.06-.2-1.06l-1.09,0q-7,0-14,0l-1,0s-.16.63-.25,1c-.84,3.88-1.77,7.74-2.63,11.62-.19.88-.45,1.94-.45,1.94l-3.76-.12c.27-1.19.52-2.38.8-3.57q1.78-7.42,3.57-14.87c1.2-5.07,2.33-10.16,3.59-15.22.69-2.77,2-5.11,5.05-6,4.22-1.21,8.11,1.3,9.21,6,1,4.27,2,8.55,3,12.82.93,3.94,1.93,7.87,2.86,11.81.65,2.77,1.25,5.55,1.87,8.33C81.05,181.79,81.13,182.14,81.16,182.38ZM59.1,164.05H73.25c-.14-.62-.25-1.05-.35-1.48-1-4.2-1.9-8.41-2.91-12.6a7.54,7.54,0,0,0-1.1-2.66,3.24,3.24,0,0,0-3.63-1.21,3.72,3.72,0,0,0-2.73,3c-1,4.31-2,8.62-3,12.93C59.41,162.68,59.27,163.32,59.1,164.05Z"
          />
          <path
            className="cls-2"
            d="M165.74,142.49a2.73,2.73,0,0,1,3.42,1.93,8,8,0,0,1,.23,2q0,11.44,0,22.88a10.25,10.25,0,0,0,1.85,6c2.24,3.16,5.53,4,9.16,3.68,6.19-.47,8.93-5.3,8.89-10.72-.06-7.25,0-14.5,0-21.75a12,12,0,0,1,.1-1.8c.27-1.77,1.5-2.61,3.45-2.28v1.18q0,13,0,26a12.41,12.41,0,0,1-11.26,12.73,15.66,15.66,0,0,1-7.66-.72c-4.7-1.77-7.54-5.15-7.95-10.21-.28-3.36-.22-6.75-.24-10.13,0-5.9,0-11.8,0-17.7Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Svg
