import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#3C8BD9"
            d="M72,40.1c1.7-4.4,4-8.5,7.5-11.8c13.8-13.5,36.7-10.1,46.1,6.8c7.1,12.8,14.5,25.4,21.8,38.1 c12.1,21.1,24.4,42.2,36.4,63.3c10.1,17.7-0.8,40.1-20.9,43.1c-12.3,1.8-23.8-3.8-30.1-14.8c-10.6-18.5-21.4-37.1-32-55.6 c-0.2-0.4-0.5-0.8-0.8-1.1c-1.1-0.9-1.6-2.3-2.3-3.5c-4.7-8.3-9.6-16.6-14.3-24.8c-3-5.4-6.2-10.6-9.2-16c-2.8-4.8-4-10-3.9-15.5 C70.4,45.5,70.8,42.7,72,40.1"
          />
        </g>
        <g>
          <path
            fill="#FABC04"
            d="M72,40.1c-0.6,2.5-1.2,5.1-1.3,7.8c-0.2,5.9,1.3,11.4,4.2,16.6c7.8,13.3,15.5,26.7,23.2,40.1 c0.7,1.2,1.3,2.4,2,3.5c-4.2,7.3-8.5,14.6-12.8,21.9c-5.9,10.2-11.8,20.5-17.8,30.7c-0.3,0-0.4-0.1-0.4-0.4 c-0.1-0.6,0.1-1.1,0.3-1.6c2.9-10.6,0.5-20-6.8-28c-4.4-4.9-10.1-7.6-16.6-8.5c-8.5-1.2-15.9,1-22.6,6.3c-1.2,0.9-2,2.3-3.4,3 c-0.3,0-0.4-0.1-0.5-0.4c3.4-5.9,6.7-11.7,10.1-17.6c14-24.3,27.9-48.5,42-72.7C71.7,40.6,71.9,40.4,72,40.1"
          />
        </g>
        <g>
          <path
            fill="#34A852"
            d="M19.8,131.4c1.3-1.2,2.6-2.5,4-3.6c17.1-13.5,42.9-3.7,46.6,17.7c0.9,5.1,0.4,10.1-1.1,15 c-0.1,0.4-0.1,0.8-0.3,1.2c-0.6,1.1-1.2,2.3-1.9,3.5c-6.3,10.4-15.5,15.5-27.6,14.7c-13.9-1-24.8-11.4-26.7-25.2 c-0.9-6.7,0.4-13,3.9-18.8c0.7-1.3,1.6-2.4,2.3-3.7C19.3,132,19.1,131.4,19.8,131.4"
          />
        </g>
        <g>
          <path fill="#FABC04" d="M19.8,131.4c-0.3,0.3-0.3,0.8-0.8,0.8c-0.1-0.5,0.2-0.8,0.5-1.1L19.8,131.4" />
        </g>
        <g>
          <path fill="#E1C025" d="M69,161.7c-0.3-0.5,0-0.8,0.3-1.2c0.1,0.1,0.2,0.2,0.3,0.3L69,161.7" />
        </g>
      </g>
    </svg>
  )
}

export default Svg
