import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      style={{
        enableBackground: "new 0 0 200 200",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <rect
        x={0}
        style={{
          fill: "#FA8A3B",
        }}
        width={200}
        height={200}
      />
      <g>
        <path
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "#FFFFFF",
          }}
          d="M142.1,126.8c-5.4-0.1-9.7,0.4-13.2,1.9 c-1,0.4-2.6,0.4-2.8,1.7c0.5,0.5,0.6,1.4,1.1,2.2c0.8,1.4,2.2,3.2,3.5,4.1c1.4,1.1,2.9,2.2,4.4,3.1c2.6,1.6,5.6,2.6,8.2,4.2 c1.5,1,3,2.2,4.5,3.2c0.7,0.5,1.2,1.4,2.2,1.8v-0.2c-0.5-0.6-0.6-1.5-1.1-2.2c-0.7-0.7-1.4-1.3-2-2c-2-2.7-4.4-5-7.1-6.9 c-2.2-1.5-6.9-3.5-7.8-6c0,0-0.1-0.1-0.1-0.1c1.5-0.1,3.3-0.7,4.7-1.1c2.3-0.6,4.4-0.5,6.8-1.1c1.1-0.3,2.2-0.6,3.3-1v-0.6 c-1.2-1.2-2.1-2.9-3.4-4c-3.5-3-7.3-5.9-11.2-8.4c-2.1-1.4-4.8-2.2-7.1-3.4c-0.8-0.4-2.2-0.6-2.6-1.3c-1.2-1.5-1.9-3.5-2.8-5.2 c-2-3.7-3.9-7.9-5.6-11.8c-1.2-2.7-2-5.3-3.5-7.8c-7-11.6-14.6-18.6-26.3-25.4c-2.5-1.4-5.5-2-8.7-2.8c-1.7-0.1-3.4-0.2-5.1-0.3 c-1.1-0.5-2.2-1.8-3.1-2.4c-3.9-2.4-13.9-7.8-16.7-0.7c-1.8,4.4,2.7,8.8,4.3,11c1.2,1.6,2.7,3.3,3.5,5.1c0.5,1.2,0.6,2.4,1.1,3.6 c1.1,3,2.1,6.3,3.5,9.1c0.7,1.4,1.6,2.9,2.5,4.2c0.5,0.8,1.5,1.1,1.7,2.3c-0.9,1.4-1,3.4-1.6,5.1c-2.4,7.7-1.5,17.2,2,22.9 c1.1,1.7,3.7,5.4,7.1,4c3.1-1.2,2.4-5.1,3.3-8.5c0.2-0.8,0.1-1.4,0.5-1.9v0.1c1,1.9,1.9,3.7,2.8,5.6c2.1,3.3,5.8,6.8,8.8,9.1 c1.6,1.2,2.9,3.3,5,4.1v-0.2h-0.1c-0.4-0.6-1-0.9-1.6-1.4c-1.2-1.2-2.6-2.7-3.5-4.1c-2.9-3.8-5.4-8-7.6-12.4 c-1.1-2.1-2-4.4-2.9-6.5c-0.4-0.8-0.4-2-1.1-2.4c-1,1.5-2.5,2.8-3.3,4.6c-1.3,2.9-1.4,6.5-1.9,10.3c-0.3,0.1-0.1,0-0.3,0.1 c-2.2-0.5-2.9-2.8-3.7-4.7c-2-4.8-2.4-12.6-0.6-18.2c0.5-1.4,2.5-5.9,1.7-7.3c-0.4-1.3-1.8-2-2.5-3.1c-0.9-1.3-1.8-2.9-2.4-4.4 c-1.6-3.8-2.5-8-4.2-11.8c-0.8-1.8-2.2-3.6-3.4-5.2c-1.3-1.8-2.7-3.1-3.7-5.3c-0.3-0.8-0.8-2-0.3-2.8c0.1-0.5,0.4-0.7,1-0.9 c0.9-0.7,3.4,0.2,4.3,0.6c2.5,1,4.6,2,6.7,3.4c1,0.7,2,2,3.2,2.3h1.4c2.2,0.5,4.6,0.1,6.7,0.7c3.6,1.2,6.9,2.9,9.8,4.7 c8.9,5.6,16.3,13.7,21.2,23.3c0.8,1.6,1.2,3,1.9,4.6c1.4,3.3,3.2,6.7,4.6,10c1.4,3.2,2.8,6.5,4.8,9.1c1,1.4,5.1,2.2,6.9,2.9 c1.4,0.6,3.5,1.2,4.7,1.9c2.3,1.4,4.6,3.1,6.8,4.6C138.5,123.8,141.9,125.5,142.1,126.8"
        />
        <path
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "#FFFFFF",
          }}
          d="M72.7,67.7c-1.2,0-2,0.1-2.8,0.3v0.1h0.1 c0.5,1.1,1.5,1.8,2.2,2.8c0.5,1.1,1,2.2,1.6,3.3c0.1-0.1,0.1-0.1,0.1-0.1c1-0.7,1.4-1.8,1.4-3.4c-0.4-0.5-0.5-1-0.8-1.4 C74.2,68.6,73.3,68.3,72.7,67.7"
        />
      </g>
    </svg>
  )
}

export default Svg
