import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#E42313" width={200} height={200} />
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M87.4,78.6H97l-5.1-8.2l-9.3,14.8l-4.3,0l11.3-17.8c0.5-0.7,1.3-1.2,2.2-1.2c0.9,0,1.7,0.4,2.2,1.1 l11.4,17.8l-4.3,0l-2-3.3h-9.7L87.4,78.6"
              />
              <path
                fill="#FFFFFF"
                d="M131.5,81.9V66.4h-3.6v17c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.3,0.5h16.4l2.1-3.3H131.5"
              />
              <path
                fill="#FFFFFF"
                d="M71.9,79.1c3.5,0,6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3H56.1v18.7h3.6V69.7h11.9c1.7,0,3,1.4,3,3 c0,1.7-1.4,3-3,3l-10.2,0l10.8,9.4h5.2l-7.2-6.1L71.9,79.1"
              />
              <path
                fill="#FFFFFF"
                d="M33.9,85.2c-5.2,0-9.4-4.2-9.4-9.4c0-5.2,4.2-9.4,9.4-9.4h10.9c5.2,0,9.4,4.2,9.4,9.4 c0,5.2-4.2,9.4-9.4,9.4L33.9,85.2 M44.6,81.9c3.4,0,6.1-2.7,6.1-6.1c0-3.4-2.7-6.1-6.1-6.1H34.2c-3.4,0-6.1,2.7-6.1,6.1 c0,3.3,2.7,6.1,6.1,6.1H44.6"
              />
              <path
                fill="#FFFFFF"
                d="M113,85.2c-5.2,0-9.4-4.2-9.4-9.4c0-5.2,4.2-9.4,9.4-9.4h12.9l-2.1,3.3h-10.6c-3.4,0-6.1,2.7-6.1,6.1 c0,3.3,2.7,6.1,6.1,6.1h13l-2.1,3.3L113,85.2"
              />
              <path
                fill="#FFFFFF"
                d="M157.1,81.9c-2.8,0-5.1-1.9-5.8-4.4h15.4l2.1-3.3h-17.5c0.7-2.6,3.1-4.4,5.8-4.4h10.6l2.1-3.3h-12.9 c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4h11.1l2.1-3.3L157.1,81.9"
              />
              <path
                fill="#FFFFFF"
                d="M171.7,68.4c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7 C172.4,70.1,171.7,69.3,171.7,68.4 M173.3,70.5c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1 C171.2,69.6,172.2,70.5,173.3,70.5 M173.1,67.2c0.3,0,0.5,0,0.6,0.1c0.4,0.1,0.4,0.5,0.4,0.7c0,0,0,0.1,0,0.2 c0,0.1-0.1,0.3-0.3,0.4c0,0,0,0-0.1,0.1l0.5,1h-0.5l-0.5-0.9H173v0.9h-0.5v-2.4L173.1,67.2 M173.3,68.3c0.1,0,0.3,0,0.4-0.1 c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0-0.4,0H173v0.7H173.3"
              />
            </g>
          </g>
          <rect x={26.1} y={98.5} fill="#FFFFFF" width={147.8} height={1.5} />
        </g>
        <g id="sn31ot.tif_2_">
          <g>
            <path
              fill="#FFFFFF"
              d="M89.3,131.7c-2.2,2.6-4.9,3.9-8.2,3.9c-5.3-0.1-9.8-4.8-9.6-9.8c1.3,0,2.7,0,4,0c0,0.1,0,0.3,0.1,0.4 c0.4,3.7,3.9,6.1,7.5,5.1c2.7-0.8,4.2-3.1,4.2-6.3c0.3-1.9,0.9-3.6,2.1-5.1c3.7-4.6,9.8-5,13.8-1.9c2.6,2,4,4.6,4.1,7.9 c0.1,7.1,0,14.2,0,21.3c0,0.1,0,0.2,0,0.4c-1.3,0-2.7,0-4.1,0c0-4.6,0-9.1,0-13.7c0-2.3,0-4.7,0-7c0.5-4.2-3.3-7.6-7.4-6.5 c-2.1,0.5-4.6,2.6-4.4,6.3C91.1,128.4,90.5,130.2,89.3,131.7z"
            />
            <path
              fill="#FFFFFF"
              d="M49.3,127.1c0.4,2,1.4,3.4,3.3,4c2.2,0.7,4.2,0.4,6-1.1c0.4-0.3,0.7-0.3,1.1-0.1c1,0.5,2.1,1,3.2,1.5 c-1.4,2-3.2,3.3-5.6,3.7c-3.6,0.7-7,0.3-9.8-2.5c-2.8-2.8-3.5-7.5-1.7-11.3c1.7-3.6,5.4-5.6,9.5-5.3c5.4,0.4,9.3,4.9,8.7,10.9 C59.1,127.1,54.2,127.1,49.3,127.1z M49.6,123.4c3.3,0,6.5,0,9.8,0c-0.4-2-2.4-3.4-4.8-3.4C52.1,120,50.1,121.4,49.6,123.4z"
            />
            <path
              fill="#FFFFFF"
              d="M149,135.5c-1.4,0-2.7,0-4.1,0c0-0.7,0-1.4,0-1.9c-1.4,0.6-2.6,1.3-4,1.6c-5.3,1.2-10.5-2.1-11.6-7.3 c-1.2-5.3,2.1-10.5,7.4-11.7c6.3-1.4,12.3,3.2,12.3,9.7C149,129.1,149,132.3,149,135.5z M138.9,131.6c3.3,0,5.9-2.6,5.9-5.9 c0-3.2-2.6-5.7-5.8-5.7c-3.3,0-6,2.7-6,5.8C133,129,135.6,131.6,138.9,131.6z"
            />
            <path
              fill="#FFFFFF"
              d="M108.2,115.9c1.4,0,2.7,0,4.1,0c0,0.3,0,0.6,0,0.9c0,2.9,0,5.7,0,8.6c0,3.4,2.6,6,5.9,6 c3.3,0,5.9-2.6,5.9-6c0-3.1,0-6.3,0-9.5c1.4,0,2.7,0,4.1,0c0,0.2,0.1,0.4,0.1,0.6c0,3,0,6,0,8.9c0,5.5-4.4,9.9-9.7,10 c-5.5,0.1-10-4.1-10.3-9.6C108.1,122.7,108.2,119.3,108.2,115.9z"
            />
            <path
              fill="#FFFFFF"
              d="M69.9,106.4c0,9.7,0,19.3,0,29c-1.3,0-2.6,0-4,0c0-9.7,0-19.3,0-29C67.2,106.4,68.5,106.4,69.9,106.4z"
            />
            <path
              opacity={0.6}
              fill="#FFFFFF"
              d="M89.4,119.8c-1.2,1.5-1.8,3.2-2.1,5.1c-0.1-0.3-0.2-0.5-0.3-0.8c-1-3-4.1-4.8-7.2-3.9 c-2.7,0.8-4.1,2.7-4.4,5.5c-1.3,0-2.7,0-4,0c0.1-3.7,1.7-6.5,4.8-8.4c4.2-2.6,9.5-1.7,12.7,2C89.1,119.5,89.2,119.7,89.4,119.8z"
            />
            <path
              opacity={0.6}
              fill="#FFFFFF"
              d="M103.1,126.7c0,2.3,0,4.7,0,7c-1.6,1.1-3.4,1.8-5.4,1.8c-2.8,0.1-5.3-0.8-7.4-2.8 c-0.4-0.3-0.7-0.7-1.1-1.1c1.2-1.5,1.8-3.3,2.1-5.2c0.1,0.3,0.2,0.6,0.3,1c1.3,3.7,5.8,5.2,9,2.9 C102,129.5,102.7,128.2,103.1,126.7z"
            />
            <path
              fill="#FFFFFF"
              d="M154.3,135c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1,0.4-0.1,0.8-0.2,1.2c0,0-0.1,0-0.1,0c0-0.7,0-1.4,0-2.2 c0.3,0.5,0.6,0.9,0.9,1.3c0.3-0.5,0.5-0.9,0.7-1.3c0.1,0,0.1,0,0.2,0.1c0,0.7,0,1.5,0,2.2c-0.1,0-0.1,0-0.2,0 c-0.1-0.3-0.1-0.6-0.2-1.1C154.6,134.6,154.5,134.8,154.3,135z"
            />
            <path
              fill="#FFFFFF"
              d="M152.8,133.2c-1.1,0.5-0.2,1.5-0.7,2.1c-0.6-0.6,0.5-1.7-0.7-2.1C151.8,133.2,152.3,133.2,152.8,133.2z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Svg
