import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path fill="#E24329" d="M100,173.7L129.5,83H70.5L100,173.7L100,173.7z" />
        <path fill="#FC6D26" d="M100,173.7L70.5,83H29.3L100,173.7L100,173.7z" />
        <path fill="#FCA326" d="M29.3,83l-9,27.6c-0.8,2.5,0.1,5.3,2.2,6.8l77.5,56.3L29.3,83L29.3,83z" />
        <path fill="#E24329" d="M29.3,83h41.3L52.8,28.4c-0.9-2.8-4.9-2.8-5.8,0L29.3,83L29.3,83z" />
        <path fill="#FC6D26" d="M100,173.7L129.5,83h41.3L100,173.7L100,173.7z" />
        <path fill="#FCA326" d="M170.7,83l9,27.6c0.8,2.5-0.1,5.3-2.2,6.8L100,173.7L170.7,83L170.7,83z" />
        <path fill="#E24329" d="M170.7,83h-41.3l17.7-54.6c0.9-2.8,4.9-2.8,5.8,0L170.7,83L170.7,83z" />
      </g>
    </svg>
  )
}

export default Svg
